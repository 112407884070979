var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"data-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"indigo","icon":"mdi-account","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('user'))+" ")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto responsive-table-search-input",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{staticClass:"responsive-table",attrs:{"options":_vm.options,"server-items-length":_vm.totalUsers,"headers":_vm.headers,"items":_vm.users,"footer-props":{
        'items-per-page-options': [10, 20, 50, 100],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[0].text}},[_vm._v(" "+_vm._s(row.item.username)+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[1].text}},[_vm._v(" "+_vm._s(row.item.metadata.firstName)+" "+_vm._s(row.item.metadata.lastName)+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[2].text}},[_vm._v(" "+_vm._s(row.item.email)+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[3].text}},[_vm._v(" "+_vm._s(row.item.evalid)+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[4].text}},[_vm._v(" "+_vm._s(_vm._f("formatDateForTable")(row.item.createdAt))+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[5].text}},[_vm._v(" "+_vm._s(row.item.generatedPassword)+" ")]),_c('td',{class:!row.item.activated ? 'row-opacity' : '',attrs:{"data-label":_vm.headers[6].text}},[(row.item.uuid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"dark":"","color":row.item.activated ? 'success' : 'grey'}},on),[_vm._v(" "+_vm._s(row.item.activated ? 'mdi-check' : row.item.activationEmailSent ? 'mdi-timer-sand' : 'mdi-send')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.getUserStatus(row.item)))+" ")])]):_vm._e()],1),_c('td',{staticStyle:{"text-align":"right"},attrs:{"data-label":_vm.headers[7].text}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(row.item.uuid)?_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.clickUpdateUser(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-pen ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.edit-user')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"#db3236"},on:{"click":function($event){return _vm.clickDeleteUser(row.item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-user')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({class:("mx-2 " + (_vm.userIsSelected(row.item) ? '' : 'lighten-3')),attrs:{"small":"","color":"indigo"},on:{"click":function($event){return _vm.toggleUserForExport(row.item)}}},on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.userIsSelected(row.item) ? 'mdi-checkbox-multiple-marked' : 'mdi-checkbox-multiple-blank')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.mark-user')))])])],1)])]}}])}),_c('v-divider'),_c('v-row',{staticClass:"mx-auto pt-6",attrs:{"justify":"end","align":"center"}},[_c('span',{staticStyle:{"margin-right":"15px"}},[_vm._v(_vm._s(_vm.usersForExport.length)+" "+_vm._s(_vm.$t('dialog.users-selected')))]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"green","fab":"","small":""},on:{"click":_vm.clickCreateUser}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-user')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"indigo","fab":"","small":"","disabled":_vm.everyUserGotActivationEmail},on:{"click":_vm.sendMissingActivations}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-send ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.send-missing-activations')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.selectAllUsersForExport()}}},on),[_c('v-icon',[_vm._v(" "+_vm._s('mdi-checkbox-multiple-marked')+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.select-all-users')))])])],1),_c('v-row',{staticClass:"mx-auto py-2",attrs:{"justify":"end","align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","fab":"","small":"","disabled":!_vm.usersForExport.length},on:{"click":_vm.openAddUsersToCompanyDialog}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-factory ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.add-selected-users-to-company')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","fab":"","small":"","disabled":!_vm.usersForExport.length},on:{"click":_vm.openCertificateWizard}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-certificate-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.create-certificates')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"secondary","fab":"","small":"","disabled":!_vm.usersForExport.length},on:{"click":_vm.clickExportCsv}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-table ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.csv-export')))])])],1),[(_vm.dialog)?_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('dashboard-forms-wizard',{on:{"wizardfinished":_vm.handleWizardFinished}})],1):_vm._e()]],2),_c('v-dialog',{model:{value:(_vm.createCertificateDialog),callback:function ($$v) {_vm.createCertificateDialog=$$v},expression:"createCertificateDialog"}},[(_vm.createCertificateDialog)?_c('certificate-wizard',{attrs:{"pre-selected-users":_vm.usersForExport},on:{"wizardcanceled":function($event){_vm.createCertificateDialog = false},"wizardfinished":_vm.wizardFinished}}):_vm._e()],1),_c('v-dialog',{model:{value:(_vm.addUsersToCompanyDialog),callback:function ($$v) {_vm.addUsersToCompanyDialog=$$v},expression:"addUsersToCompanyDialog"}},[_c('v-card',[_c('v-card-text',{staticStyle:{"display":"flex","align-items":"center"}},[(_vm.companies.length)?_c('v-autocomplete',{staticClass:"mr-5",attrs:{"autocomplete":Date.now(),"items":_vm.companies,"color":"secondary","label":_vm.$t('company'),"return-object":"","item-text":"name","prepend-icon":"mdi-factory"},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}):_vm._e(),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.addSelectedUsersToCompany}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }