































































































































































































































































































































  import { IUser, User } from '@/shared/model/user.model'
  import userService from '@/shared/services/userService'
  import Vue from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import { debounceInput, downloadFile } from '@/shared//helpers'
  import companyService from '@/shared/services/companyService'
  import axios from 'axios'
  import { CompanyRole, ICompany } from '@/shared/model/company.model'

  export default Vue.extend({
    name: 'DashboardDataTables',
    components: {
      DashboardFormsWizard: () => import('../user/Wizard.vue'),
      CertificateWizard: () => import('@/views/app/certificate/CertificateWizard.vue'),
    },

    data () {
      return {
        options: {} as any,

        dialog: false,
        selectedUser: new User(),

        debounce: undefined as number | undefined,

        search: '',
        usersForExport: [] as IUser[],
        everyUserGotActivationEmail: true,
        createCertificateDialog: false,
        addUsersToCompanyDialog: false,

        companies: [] as ICompany[],
        selectedCompany: null as ICompany | null,
      }
    },

    computed: {
      ...mapGetters({
        users: 'userStore/getList',
        totalUsers: 'userStore/getTotal',
      }),
      headers (): any {
        return [
          {
            text: this.$t('username'),
            value: 'username',
          },
          {
            text: this.$t('fullname'),
            value: 'fullname',
          },
          {
            text: this.$t('email'),
            value: 'email',
          },
          {
            text: this.$t('eval-id'),
            value: 'evalid',
          },
          {
            text: this.$t('created-on'),
            value: 'createdAt',
          },
          {
            text: this.$t('generated-password'),
            value: 'generatedPassword',
          },
          {
            text: this.$t('status'),
            value: 'activated',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
    },
    watch: {
      options: {
        async handler () {
          this.getAllUsersWithVDataTable()
        },
      },
      search: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllUsersWithVDataTable)
          this.options.page = 1
        },
      },
    },
    created () {
      this.getEveryUserGotActivationEmail()
    },
    methods: {
      ...mapActions('userStore', {
        createUser: 'createItem',
        deleteUser: 'deleteItem',
      }),
      handleWizardFinished () {
        this.dialog = false
        this.getEveryUserGotActivationEmail()
      },
      async getAllUsersWithVDataTable () {
        const query = {
          l: this.options.itemsPerPage,
          lo: this.options.page,
          sf: this.options.sortBy[0],
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { search: this.search },
        }
        await userService.getAllUsersPaginated(query)
        this.getEveryUserGotActivationEmail()
      },
      clickCreateUser () {
        this.dialog = true
      },
      clickUpdateUser (item: IUser) {
        this.$router.push(`/app/users/${item._id}`, () => {})
      },
      clickDeleteUser (user: IUser) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-user-to')}
<strong>${user.username}</strong>
${this.$t('dialog.delete')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('dialog.delete-user').toString(),
          },
        ).then((res) => {
          if (res) {
            if (user._id) {
              this.deleteUser({ id: user._id, item: user }).then(() => {
                this.getAllUsersWithVDataTable()
              })
            } else {
              console.error('Tried to delete user without id, this should not happen')
            }
          }
        })
      },
      toggleUserForExport (user: IUser) {
        if (!this.userIsSelected(user)) {
          this.usersForExport.push(user)
        } else {
          const index = this.usersForExport.findIndex((u) => u._id === user._id)
          this.usersForExport.splice(index, 1)
        }
        if (!this.usersForExport.length) {
          this.getEveryUserGotActivationEmail()
        } else {
          this.everyUserGotActivationEmail = false
        }
      },
      selectAllUsersForExport () {
        for (const user of this.users) {
          if (!this.userIsSelected(user)) {
            this.toggleUserForExport(user)
          }
        }
      },
      userIsSelected (user: IUser) {
        return this.usersForExport.findIndex((x) => x._id === user._id) !== -1
      },
      async clickExportCsv () {
        const userIds: string[] = []
        this.usersForExport.forEach((user) => {
          if (user._id) {
            userIds.push(user._id)
          }
        })

        this.axios
          .post('/api/exportcardpressocsv', {
            users: userIds,
          })
          .then((result: any) => {
            if (result.status === 200 && result.data) {
              downloadFile(result.data, 'csvForCardPresso', 'text/csv')
            }
          })
      },
      sendMissingActivations () {
        this.everyUserGotActivationEmail = true
        this.$confirm(`${this.$t('dialog.are-you-sure')}`, {
          color: 'indigo',
          buttonTrueText: this.$t('yes').toString(),
          buttonFalseText: this.$t('no').toString(),
          title: this.$t('tooltip.send-missing-activations').toString(),
        }).then((res) => {
          if (res) {
            if (this.usersForExport.length) {
              this.axios.post('/api/senduseractivationlinks', this.usersForExport).then((result: any) => {
                if (result.status === 200 && result.data) {
                  this.getAllUsersWithVDataTable()
                }
              })
            } else {
              this.axios.get('/api/sendmissinguseractivationlinks').then((result: any) => {
                if (result.status === 200 && result.data) {
                  this.getAllUsersWithVDataTable()
                }
              })
            }
          }
        })
      },
      getUserStatus (user: IUser) {
        if (user.activated) {
          return 'user-activated'
        } else if (user.activationEmailSent) {
          return 'user-not-activated'
        } else {
          return 'user-did-not-receive-activation-email'
        }
      },
      getEveryUserGotActivationEmail () {
        this.axios.get('/api/usersRequireActivationEmail').then((result: any) => {
          if (result && result.data) this.everyUserGotActivationEmail = !result.data.usersRequireActivationEmail
        })
      },
      openCertificateWizard () {
        this.createCertificateDialog = true
      },
      wizardFinished () {
        this.createCertificateDialog = false
      },

      async userIsAlreadyInACompany (user: IUser): Promise<boolean> {
        if (user && user._id) {
          const result = await companyService.getUsersCompany(user._id)
          if (result.data && result.data.length) {
            return true
          } else {
            return false
          }
        }
        // true to avoid putting users in multiple companies
        return true
      },
      async openAddUsersToCompanyDialog () {
        for (const user of this.usersForExport) {
          if (await this.userIsAlreadyInACompany(user)) {
            this.$toast.error(`${Vue.filter('fullNameOfUser')(user)} ${this.$t('dialog.is-already-in-a-company')} `)
            return
          }
        }
        this.getAllCompaniesWithVDataTable()
        this.addUsersToCompanyDialog = true
      },
      async getAllCompaniesWithVDataTable () {
        const query = {
          l: 0,
          lo: this.options.page,
          sf: this.options.sortBy[0],
          so: this.options.sortDesc[0] ? 'desc' : 'asc',
          search: { },
        }
        await this.getList(query)
      },
      async getList ({ l, lo, sf, so, search = {} }: any) {
        try {
          // The actual limitOffset is calculated in the api
          const result = await axios.get('/api/companies', {
            params: { l: l, lo: lo - 1, sf, so, ...search },
          })
          if (result && result.data && result.data.items) {
            this.companies = result.data.items
          }
        } catch (err) {
          console.error(err)
        } finally {
        }
      },
      addSelectedUsersToCompany () {
        if (this.selectedCompany && this.selectedCompany._id) {
          if (!this.selectedCompany.users) {
            this.selectedCompany.users = []
          }
          const users = [...this.selectedCompany.users]
          for (const coworker of this.usersForExport) {
            users.push({
              user: coworker,
              companyRoles: [CompanyRole.employee],
            })
          }
          companyService
            .updateCompany(this.$store, { ...this.selectedCompany, users: users })
            .then((result) => {
              this.selectedCompany = null
              this.addUsersToCompanyDialog = false
              this.$toast(this.$t('dialog.save-changes-success').toString())
            })
            .catch((e) => {
              this.addUsersToCompanyDialog = false
              this.$toast.error(this.$t('dialog.save-company-failed').toString())
            })
        }
      },
    },
  })
